import { Divider, Typography, Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import Logo from './../../src/assets/logo.png'

function Header({ getLocale }) {
  return (
    <Stack
      sx={{
        width: "100vw",
        height: "80px",
        backgroundColor: "white",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        position: "relative",
        zIndex: 501,
      }}
    >
      <Stack
        sx={{
          alignSelf: "self-end"
        }}
        direction={"row"}
        columnGap={1}
      >
        <Box display={'flex'}>
        <img height={'80px'} src={Logo}></img>
        </Box>
     {/*    <Typography
          onClick={() => getLocale("gr")}
          sx={{ fontWeight: "bold", cursor: "pointer", color: "grey" }}
        >
          GR
        </Typography>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{
            borderRightWidth: 3,
            margin: "2px 0px 2px 0px",
            borderColor: "grey",
          }}
        />
        <Typography
          onClick={() => getLocale("en")}
          sx={{ fontWeight: "bold", cursor: "pointer", color: "grey" }}
        >
          EN
        </Typography> */}
      </Stack>
    </Stack>
  );
}

export default Header;
